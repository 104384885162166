<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Leave Register" slot="title" link="/helpContent/Leave Register For HR" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-3">
            <FormSelect label="Select Staff" :items="staffList" item-name="NAME" item-value="id" v-model="filtering.selectedStaff" rules="required"/>
          </div>
          <div class="col-sm-3">
            <FormSelect label="Select Leave Type" :items="leaveTypeList" item-name="LeaveName" item-value="ID" v-model="filtering.selectedLeaveType" rules="required"/>
          </div>
          <div class="col-sm-2">
              <Year label="Year" v-model="filtering.selectedYear" rules="required" />
          </div>
          <div class="col-sm-3">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange"
      >
    </DataTable>
    <Popup title="Information" :value="warningModal.visible" @close="() => showWarningModal(false)">
        {{ warningModal.message }}
        <div slot="footer">
            <FormButton v-if="warningModal.buttons.cancel" @click="() => showWarningModal(false)">Cancel</FormButton>
            <FormButton v-if="warningModal.buttons.confirm" type="success" @click="confirmFreeze">Confirm</FormButton>
            <FormButton v-if="warningModal.buttons.ok" @click="() => showWarningModal(false)">Ok</FormButton>
        </div>
    </Popup>
    <Popup title="Success" type="success" :value="showMessage.isVisible" @close="() => (showMessage.isVisible = false)" :closable="true">
        {{ showMessage.message }}
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import Year from "../../../components/Year";
import loadingMixin from "Mixins/loadingMixin";
import { getStaffList, leaveType, getLeaveRegisterHR } from "../api";
import tableConfig from "./table";
import validateSelectedRows from "Mixins/validateSelectedRows";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin, validateSelectedRows],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    Year,
    ValidationObserver,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      filtering: {
        selectedStaff: null,
        selectedLeaveType: null,
        selectedYear: new Date().getFullYear()
      },
      staffList: [],
      leaveTypeList: [],
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      listData: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.getAllList();
  },
  methods: {
    async getAllList() {
      await getStaffList().then((res) => {
        this.staffList = res.data;
      });
      await leaveType().then((res) => {
        this.leaveTypeList = res.data;
      });
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          setTimeout(() => {
            this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;
            const filters = {};
            filters.staffId = this.filtering.selectedStaff;
            filters.leaveTypeId = this.filtering.selectedLeaveType;
            filters.year = this.filtering.selectedYear;
            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pagesize: perPage,
                  },
                ],
              },
            };
            getLeaveRegisterHR(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }, 50);
        }
      });
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
    }
  },
};
</script>

<style lang="scss" module>
  .popupRow {
    padding: 5px 0 15px;
  }

  .pagination {
    margin: 10px 0 0;
    text-align: right;
    display: flex;
    justify-content: flex-end;
    line-height: 26px;
  }

  .paginationText {
    margin: 0 20px;
  }

  .paginationButton {
    cursor: pointer;
    width: 26px;
    text-align: center;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
      box-shadow 200ms ease;
  }

  .paginationButton:hover {
    background: #57a3f3;
    color: #fff;
    &:hover {
      box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
        0 4px 15px 0 rgba(0, 0, 0, 0.15);
    }
  }
</style>