export default [
    {
      title: "Month",
      key: "Month",
      minWidth: 80,
    },
    {
      title: "Brought Forward",
      key: "Brought Forward",
      minWidth: 80,
    },
    {
      title: "Leave From",
      key: "Leave From",
      minWidth: 80,
    },
    {
      title: "Leave To",
      key: "Leave To",
      minWidth: 80,
    },
    {
      title: "Number of Leaves",
      key: "Number of Leaves",
      minWidth: 80,
    },
    {
      title: "Total Leave Taken",
      key: "Total Leave Taken",
      minWidth: 80,
    },
    {
      title: "Balance at the End of the Month",
      key: "Balance at the End of the Month",
      minWidth: 80,
    },
];
